/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Collapse,
    Label,
    FormGroup,
    Input,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col, NavbarBrand, UncontrolledCollapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Navbar,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function ServicePage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);

    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="section profile-content">
                <Container>
                    <Row className="mt-5 mb-5">
                        <Col className="ml-auto mt-5 text-center" md="6">
                            <h2> Motion Graphics </h2>
                            <br/>
                            <p className="text-center">
                                We produce stunning custom 2D and 3D motion graphics work across various platforms; from
                                commercials to exhibition showcase and advertisements, we bring forth an exhilarating
                                narration that celebrates your brand’s values, identity, and community. Our motion
                                graphics are crafted to highlight your products and company initiatives for all
                                occasions. Whether it is a product launch, social media campaign, or an event, our
                                motion graphics would help shine a unique spotlight on your brand to stand out amongst
                                others.
                            </p>
                        </Col>
                        <Col className="mr-auto text-center" md="6">
                            <img
                                alt="..."
                                src={require("assets/img/graphics/3.png")}
                                style={{width: "70%"}}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col className="ml-auto text-center" md="6">
                            <img
                                alt="..."
                                src={require("assets/img/graphics/2.png")}
                                style={{width: "70%"}}
                            />
                        </Col>
                        <Col className="mr-auto mt-5 text-center" md="6">
                            <h2> Graphic Design </h2>
                            <br/>
                            <p className="text-center">
                                From visual aesthetics to color palettes, we carefully curate a graphic profile that
                                would enrich the way brands interact with audiences. Our experts carefully design an
                                authentic visual identity that embraces what your brand stands for and adds beautiful
                                artistic elements to your projects.

                            </p>
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col className="ml-auto mt-5 text-center" md="6">
                            <h2> Interactive Marketing </h2>
                            <br/>
                            <p className="text-center">
                                In this digital age, we have the world at our fingertips; it is more imperative than
                                ever that brands value the interaction with audiences. Designers at Bang Bang Motion are
                                experienced in constructing an immersive digital experience for users, ranging from AR
                                camera filters, advertisement gamification to O2O strategies, our marketing solutions
                                ensure that audiences can have unforgettable engagement with your brand.
                            </p>
                        </Col>
                        <Col className="mr-auto text-center" md="6">
                            <img
                                alt="..."
                                src={require("assets/img/graphics/1.png")}
                                style={{width: "70%"}}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <SimpleFooter/>
        </>
    );
}

export default ServicePage;
