/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ReactPlayer from 'react-player'
import video from "assets/img/graphics/chery/KOL.mp4";

function CheryPage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);
    const Video = () => {
        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    height="100"
                    className='react-player'
                    url= "http://bangbangmotion.com/static/media/KOL.dca4b6f9.mp4"
                    controls = {true}
                />
            </div>
        );
    };
    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row className="section-title mb-4">
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Chery Facebook Campaign</h2>
                                <h5 className="description">
                                    Graphic Design // Motion Design // Interactive Marketing
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mt-5">
                            <img
                                alt="Responsive Image"
                                src={require("assets/img/graphics/chery/banner.jpg")}
                                style={{width: "100%", borderRadius: "10px"}}
                            />
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mr-auto" md="7">
                                <h5> Project Brief </h5>
                                <br/>
                                <p className="text-center">
                                    Established in 1997, Chery is an automotive manufacturer and industry leader within
                                    the Chinese market that strives to push automobile innovation. It has earned the
                                    title of the first Chinese automobile brand to exceed 7 million units sold.
                                    <br/>
                                    <br/>
                                    With exports to over 80 countries and regions around the world, Chery aims to
                                    bolster their brand recognition and presence among global consumers with an emphasis
                                    on South American audiences. We worked with Chery to create the Facebook campaign
                                    #withCherywithLove that increased their international digital brand presence.
                                    Audiences were encouraged to participate in campaign by making a post about what they
                                    would do with their loved ones with the help of a Chery car. The campaign selected 2
                                    winners each week to receive a 50USD gift card.
                                </p>
                            </Col>
                        </Row>
                        <h5> Key Visuals </h5>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="5">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/chery/kv23.jpg")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto mt-n3" md="7">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/chery/chery2.png")}
                                    style={{width: "100%"}}
                                />
                            </Col>
                            <br/>
                            <Col className="ml-auto mr-auto" md="7">
                                <p>The campaign was tailored to Chery’s needs as it is designed to appeal to younger
                                    audiences
                                    and families, and the social media postings were made to fit the South American
                                    region. </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto">
                                <h5> Theme Posters </h5>
                                <br/>
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/chery/chery.001.png")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5 text-center">
                            <Col className="ml-auto mr-auto" md={"7"}>
                                <h5> Motion Design (KOL Promotion Video) </h5>
                                <br/>
                                <Video />
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mr-auto" md="7">
                                <h5> Data </h5>
                                <br/>
                                <p className="text-center">
                                    Lasting from July 14 to August 31, the successful campaign exceeded Chery’s KPI
                                    goals and achieved the following results:
                                    <br/>
                                    <br/>
                                    Page made impressions on over 9.7 million user <br/>
                                    Increased followers by 133K<br/>
                                    Post interactions increased by 2120.2% with 142K
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/chery/data.png")}
                                    style={{width: "60%"}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default CheryPage;
