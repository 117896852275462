/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col, Card, CardBody, CardImg, CardTitle, CardText
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function WorkPage() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <WebNavbar />
      <div className="profile-content">
        <Container>
         <div className="nav-tabs-navigation text-md-left">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggleTab("1");
                      }}
                  >
                    ALL
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggleTab("2");
                      }}
                  >
                    Interactive Marketing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggleTab("3");
                      }}
                  >
                    Motion
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => {
                        toggleTab("4");
                      }}
                  >
                    Graphic Design
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following mt-md-3" id="content" activeTab={activeTab}>
            <TabPane tabId="1" id="follows">
              <Row>
                <Col>
                  <a href="/valentino-page" target="_self">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/Ｖ_001.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 10%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Valentino SuperVee Comic</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Illustration/Comics</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="/chery-page" target="_self">
                    <Card>
                    <CardImg
                        alt="..."
                        src={require("assets/img/graphics/chery/kv23.jpg")}
                        top
                        style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                    ></CardImg>
                    <CardBody>
                      <CardTitle className="text-center"><h5>Chery Facebook Campaign</h5></CardTitle>
                      <CardText className="text-center">
                        <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                      </CardText>
                    </CardBody>
                  </Card>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href="/sticker-page" target="_self">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/mid-autumn/cover.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Mid-Autumn WeChat Sticker</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Illustration/Comics</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="/danone-page" target="_self">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/danone/bg-1.png")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Danone Essensis H5</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              </Row>
              <Row>v
                <Col>
                  <a href="/soap-page" target="_self">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/sandy's/c.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Sandy's Soap Packaging Illustration</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Illustration</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="text-center" tabId="2" id="following">
              <Row>
                <Col>
                  <a href="/chery-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/chery/kv23.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Chery Facebook Campaign</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="/danone-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/danone/bg-1.png")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Danone Essensis H5</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="text-center" tabId="3" id="motion">
              <Row>
                <Col>
                  <a href="/chery-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/chery/kv23.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Chery Facebook Campaign</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="/danone-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/danone/bg-1.png")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Danone Essensis H5</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              </Row>
            </TabPane>
            <TabPane className="text-center" tabId="4" id="graphic">
              <Row>
                <Col>
                  <a href="/chery-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/chery/kv23.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Chery Facebook Campaign</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="/danone-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/danone/bg-1.png")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Danone Essensis H5</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Motion/Interactive Marketing</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href="/soap-page" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/graphics/sandy's/c.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "260px", width: "100%", objectFit:"cover", objectPosition: "70% 70%"}}
                      ></CardImg>
                      <CardBody>
                        <CardTitle className="text-center"><h5>Sandy's Soap Packaging Illustration</h5></CardTitle>
                        <CardText className="text-center">
                          <small className=" text-muted">Graphic Design/Illustration</small>
                        </CardText>
                      </CardBody>
                    </Card>
                  </a>
                </Col>
                <Col>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <SimpleFooter />
    </>
  );
}

export default WorkPage;
