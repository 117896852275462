/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {Player} from 'video-react';

function DanonePage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);

    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row className="section-title mb-4">
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Danone Essensis H5</h2>
                                <h5 className="description">
                                    Graphic Design // Motion Design // Interactive Marketing
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mt-5">
                            <img
                                alt="Responsive Image"
                                src={require("assets/img/graphics/danone/bg-1.png")}
                                style={{width: "100%", objectFit: "cover", height: "400px", borderRadius: "10px"}}
                            />
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mr-auto" md="7">
                                <h5> Project Brief </h5>
                                <br/>
                                <p className="text-center">
                                    We had the pleasure to help Danone create an H5 interactive website for its new product:
                                    Essensis3 milk formula. The campaign helped promote the outstanding qualities of the
                                    product in a sleek and beautiful style.<br/><br/>
                                    BBM created the animation based on the graphic materials provided by Danone. The
                                    main challenge was to produce dynamic animation while, at the same time, minimizing the file size.
                                </p>
                            </Col>
                        </Row>
                        <img
                            alt="..."
                            src={require("assets/img/graphics/danone/phone.png")}
                            style={{width: "25%"}}
                            className={"mb-5"}
                        />
                        <Row className="mt-lg-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/danone/entry.gif")}
                                    style={{width: "100%"}}
                                />
                                <p className="small mt-1">
                                    Landing page of the H5 program, featuring a rotating and shining drop
                                </p>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/danone/rotate.gif")}
                                    style={{width: "100%"}}
                                />
                                <p className="small mt-1">
                                    Designed to invite the user to interact by rotating their phones
                                </p>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/danone/languan.gif")}
                                    style={{width: "100%"}}
                                />
                                <p className="small mt-1">
                                    One of the three drops to educate the users about the product
                                </p>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/danone/lvguan.gif")}
                                    style={{width: "100%"}}
                                />
                                <p className="small mt-1">
                                    One of the three drops to educate the users about the product
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default DanonePage;
