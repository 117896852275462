/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, Nav, NavItem, NavLink, NavbarBrand, UncontrolledCollapse, Navbar,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function ContactPage() {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("contact-page");
        return function cleanup() {
            document.body.classList.remove("contact-page");
        };
    });
    return (
        <>
            <WebNavbar />
            <div className="main">
                <div>
                    <Container>
                        <Row className="section-title text-center mb-4">
                            <Col className="ml-auto mr-auto">
                                <h2 className="title">Contact Us</h2>
                                <h5 className="description">
                                    hello@thedivegroup.com
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mb-5 pb-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/shanghai.png")}
                                    style={{width: "100%", borderRadius: "10px"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto mt-auto mb-auto" md="3">
                                <h5 className="mb-5">SHANGHAI</h5>
                                <p>Building 3, Floor 5,
                                    600 North Shanxi Road</p>
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/paris.png")}
                                    style={{width: "100%", borderRadius: "10px"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto mt-auto mb-auto" md="2">
                                <h5 className="mb-5">PARIS</h5>
                                <p>5 Parvis Alan Turing, 75013 Paris</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <SimpleFooter />
        </>
    );
}

export default ContactPage;
