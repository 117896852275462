/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import 'assets/fonts/normal.ttf';
import 'assets/fonts/light.ttf';
import 'assets/fonts/Neue.ttf';

// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import WorkPage from "views/examples/WorkPage.js";
import ServicePage from "views/examples/ServicePage.js";
import AboutPage from "views/examples/AboutPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ContactPage from "views/examples/ContactPage.js";
import CheryPage from "views/examples/Chery.js";
import ValentinoPage from "views/examples/Valentino.js";
import StickerPage from "views/examples/Sticker.js";
import DanonePage from "views/examples/Danone.js";
import SoapPage from "views/examples/Soap.js";
import BlogPage from "views/examples/BlogPage.js";
import JoinPage from "views/examples/JoinPage.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      />
      <Route
        path="/landing-page"
        render={(props) => <LandingPage {...props} />}
      />
      <Route
        path="/work-page"
        render={(props) => <WorkPage {...props} />}
      />
      <Route
        path="/register-page"
        render={(props) => <RegisterPage {...props} />}
      />
      <Route
          path="/contact-page"
          render={(props) => <ContactPage {...props} />}
      />
      <Route
          path="/service-page"
          render={(props) => <ServicePage {...props} />}
      />
      <Route
          path="/about-page"
          render={(props) => <AboutPage {...props} />}
      />
      <Route
          path="/chery-page"
          render={(props) => <CheryPage {...props} />}
      />
      <Route
          path="/valentino-page"
          render={(props) => <ValentinoPage {...props} />}
      />
      <Route
          path="/sticker-page"
          render={(props) => <StickerPage {...props} />}
      />
      <Route
          path="/danone-page"
          render={(props) => <DanonePage {...props} />}
      />
      <Route
          path="/soap-page"
          render={(props) => <SoapPage {...props} />}
      />
      <Route
          path="/blog-page"
          render={(props) => <BlogPage {...props} />}
      />
      <Route
          path="/join-page"
          render={(props) => <JoinPage {...props} />}
      />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
