/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer text-white fixed-bottom text-center">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="3" className="text-right mt-5">
                <i className="nc-icon nc-send x3"/>
              </Col>
              <Col lg="3" className="text-right border-right mt-5">
                <h5 className="txt-white mb-4">
                  hello@thedivegroup.com
                </h5>
                <h5 className="txt-white mb-0">
                  Shanghai
                  <br/>
                  Paris
                </h5>
              </Col>
              <Col lg="1" className="text-center mt-5">
                <i className="nc-icon nc-tap-01 x3"/>
              </Col>
              <Col className="text-left btn-wrapper mt-5 icons-container" lg="5">
                <a href={"https://www.linkedin.com/company/thedivegroup/"}>
                  <img
                      src={require("assets/img/logo/LI-Logo.png")}
                      style={{width: "60%", borderRadius: "10px"}}
                      alt={"LI-Logo"}>
                  </img>
                </a>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
