/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col, Card, CardBody, CardImg, CardTitle, CardText, CardImgOverlay
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function BlogPage() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [show, toggleShow] = React.useState(false);
  function visible(){
    if(!show) {
      toggleShow(!show);
    }
  }
  function hide(){
    if(show) {
      toggleShow(!show);
    }
  }
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <WebNavbar />
      <div className="profile-content">
        <Container>
         <div className="nav-tabs-navigation text-md-left">
            <div className="nav-tabs-wrapper">
              <Nav role="tablist" tabs>
                <NavItem>
                  <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        toggleTab("1");
                      }}
                  >
                    Blog
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        toggleTab("2");
                      }}
                  >
                    Insight Report
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        toggleTab("3");
                      }}
                  >
                    News & Event
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      className={activeTab === "4" ? "active" : ""}
                      onClick={() => {
                        toggleTab("4");
                      }}
                  >
                    Trend Report
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
          {/* Tab panes */}
          <TabContent className="following mt-md-3" id="content" activeTab={activeTab}>
            <TabPane tabId="1" id="follows">
              <Row>
                <Col className="mr-3">
                  <a href="#" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/grey.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "300px", width: "100%", objectFit:"cover", objectPosition: "10px 10%"}}
                      ></CardImg>
                      <CardImgOverlay className="h-100 d-flex flex-column justify-content-end">
                        <CardTitle className="ml-3"><h3>THIS IS THE TITLE</h3></CardTitle>
                        <CardText className="ml-4">
                          <small className=" text-muted">subtitle|subtitle|subtitle</small>
                        </CardText>
                      </CardImgOverlay>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="#" target="_blank">
                    <Card>
                    <CardImg
                        alt="..."
                        src={require("assets/img/grey.jpg")}
                        top
                        style={{ borderRadius: "0", display: "block", height: "300px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                    ></CardImg>
                      <CardImgOverlay className="h-100 d-flex flex-column justify-content-end">
                        <CardTitle className="ml-3"><h3>THIS IS THE TITLE</h3></CardTitle>
                        <CardText className="ml-4">
                          <small className=" text-muted">subtitle|subtitle|subtitle</small>
                        </CardText>
                      </CardImgOverlay>
                  </Card>
                  </a>
                </Col>
              </Row>
              <Row className="mt-4 mb-5">
                <Col className="mr-3">
                  <a href="#" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/grey.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "300px", width: "100%", objectFit:"cover", objectPosition: "10px 10%"}}
                      ></CardImg>
                      <CardImgOverlay className="h-100 d-flex flex-column justify-content-end">
                        <CardTitle className="ml-3"><h3>THIS IS THE TITLE</h3></CardTitle>
                        <CardText className="ml-4">
                          <small className=" text-muted">subtitle|subtitle|subtitle</small>
                        </CardText>
                      </CardImgOverlay>
                    </Card>
                  </a>
                </Col>
                <Col>
                  <a href="#" target="_blank">
                    <Card>
                      <CardImg
                          alt="..."
                          src={require("assets/img/grey.jpg")}
                          top
                          style={{ borderRadius: "0", display: "block", height: "300px", width: "100%", objectFit:"cover", objectPosition: "10px 70%"}}
                      ></CardImg>
                      <CardImgOverlay className="h-100 d-flex flex-column justify-content-end">
                        <CardTitle className="ml-3"><h3>THIS IS THE TITLE</h3></CardTitle>
                        <CardText className="ml-4">
                          <small className=" text-muted">subtitle|subtitle|subtitle</small>
                        </CardText>
                      </CardImgOverlay>
                    </Card>
                  </a>
                </Col>
              </Row>

            </TabPane>
            <TabPane className="text-center" tabId="2" id="following">
            </TabPane>
            <TabPane className="text-center" tabId="3" id="motion">
            </TabPane>
            <TabPane className="text-center" tabId="4" id="graphic">
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <SimpleFooter />
    </>
  );
}

export default BlogPage;
