/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {Player} from 'video-react';

function SoapPage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);

    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row className="section-title mb-4">
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Sandy’s Soap Packaging Illustration</h2>
                                <h5 className="description">
                                    Graphic Design
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mt-5">
                            <img
                                alt="Responsive Image"
                                src={require("assets/img/graphics/sandy's/c.jpg")}
                                style={{width: "100%", objectFit: "cover", height: "400px", borderRadius: "10px"}}
                            />
                        </Row>
                        <Row style={{height: "50px"}}></Row>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="7">
                                <h5> Project Brief </h5>
                                <br/>
                                <p className="text-center">
                                    The illustration style is partly inspired from Williams Morris. The brand concept
                                    also shares the spirit of Art Nouveau, which focuses on personalized, handcrafted
                                    works.
                                </p>
                            </Col>
                        </Row>
                        <Row style={{height: "80px"}}></Row>
                        <Row>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/sandy's/c.jpg")}
                                    style={{width: "90%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/sandy's/l.jpg")}
                                    style={{width: "90%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/sandy's/r.jpg")}
                                    style={{width: "90%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/sandy's/v.jpg")}
                                    style={{width: "90%"}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default SoapPage;
