/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Input,
    Container,
    Row,
    Col,
    Form, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SectionExamples from "views/index-sections/SectionExamples.js"
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function AboutPage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row>
                            <img
                                alt="Responsive Image"
                                src={require("assets/img/graphics/about.png")}
                                style={{width: "100%", objectFit: "cover", height: "400px", borderRadius: "10px"}}
                            />
                        </Row>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="8">
                                <h3 className="title">Company</h3>
                                <p>Bang Bang Motion is made up of a group of visual storytellers dedicated to bringing
                                    your brand messages to life. We are committed to transform a brand’s visual
                                    narrative and strive to provide pivotal and exciting digital experiences. Our
                                    experts are here to deliver world-class, bespoke designs that is creatively tailored
                                    to help global brands and companies achieve their goals.
                                </p>
                                <br/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section section-gray text-center">
                    <Container>
                        <h3 className="title mb-5">Recent Clients</h3>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/logo/valentino.png")}
                                    style={{width: "50%", filter: "grayscale(100%)"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/logo/danone.png")}
                                    style={{width: "50%", filter: "grayscale(100%)"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/logo/chery.png")}
                                    style={{width: "50%", filter: "grayscale(100%)"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/logo/bilibili.png")}
                                    style={{width: "50%", filter: "grayscale(100%)"}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section">
                    <Container>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mt-auto mb-auto text-center" md="6">
                                <h2> TEAM </h2>
                                <br/>
                                <p className="text-center">
                                    The Bang Bang Motion design team is motivated to create meaningful and memorable visual
                                    experiences for our clients. Elevated by aesthetic ingenuity and supported by effective
                                    marketing strategies, our work provides impactful impressions on audiences.
                                </p>
                            </Col>
                            <Col className="mr-auto text-center" md="6">
                                <img
                                    alt="..."
                                    src={require("assets/img/graphics/4.png")}
                                    style={{width: "70%"}}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default AboutPage;
