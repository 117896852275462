/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function ValentinoPage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);

    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row className="section-title mb-4">
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Valentino Supervee Comic</h2>
                                <h5 className="description">
                                    Illustration // Comics
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mt-5 text-left" md="3">
                                <h5> Project Brief </h5>
                                <br/>
                                <p className="text-left">
                                    A contemporary narrative. To celebrate the Valentino Garavani SuperVee bag, the
                                    Maison introduces SuperVee Comics, a multipart story separated into three chapters,
                                    each created by a voice in illustration and worldmaking.<br/>
                                    <br/>
                                    The comic was created from the storyline provided by Valentino.
                                </p>
                            </Col>
                            <Col className="mr-auto" md="4">
                                <img
                                    alt="..."
                                    src={require("assets/img/graphics/cover.png")}
                                    style={{width: "70%"}}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-3">
                            <Col className="ml-auto mr-auto text-left" md="7">
                                <h5> About the author, Felicity Bang </h5>
                                <br/>
                                <p className="text-left">
                                    Illustrator Felicity Bang is one of the virtual illustrators and 3D artists at Bang
                                    Bang Motion, based in both Shanghai and Paris. She is super tech-savvy and also
                                    madly in love with the comics world. She is the new type of Gen-Z or
                                    slasher-generation in China that devotes herself in many different areas with
                                    passion including sustainability. Her favorite singer is Billie Eilish and she enjoys music from the 70s. She loves quality things with good design; she is the
                                    muse of others, often travelling the world and never afraid to try new things, yet
                                    maintaining a low-key lifestyle. She has “artist dates” with herself once a week to
                                    gestate more ideas and inspiration. She sees herself as an observer of the world and
                                    finds beauty in every little thing.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col className="ml-auto text-left" md="3">
                                <p className="text-left">
                                    WORKS: <br/>
                                    2019, “Ecoholist” <br/>
                                    2020, “The Quarantine”
                                </p>
                            </Col>
                            <Col className="mr-auto" md="4">
                                <img
                                    alt="..."
                                    src={require("assets/img/graphics/signature.png")}
                                    style={{width: "100%"}}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-lg-5">
                            <Col className="ml-auto" md="4">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/Ｖ_001.jpg")}
                                    style={{width: "100%"}}
                                />
                            </Col>
                            <Col className="mr-auto" md="4">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/Ｖ_003.jpg")}
                                    style={{width: "99%"}}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mr-auto">
                                <h5> As Seen Online </h5>
                                <br/>
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/web.png")}
                                    style={{width: "100%"}}
                                />
                            </Col>
                        </Row>
                        <h5> As Seen on Instagram </h5>
                        <Row className="mt-5">
                            <Col className="ml-auto" md="3">
                                <a href={"https://www.instagram.com/p/CEq_ZAnJZxA/"}>
                                    <img
                                        alt="Responsive Image"
                                        src={require("assets/img/graphics/ins1.jpg")}
                                        style={{width: "80%"}}
                                    />
                                </a>
                            </Col>
                            <Col className="mr-auto" md="9">
                                <a href={"https://www.instagram.com/p/CErNkFiJCwM/"}>
                                    <img
                                        alt="Responsive Image"
                                        src={require("assets/img/graphics/ins2.jpg")}
                                        style={{width: "100%"}}
                                    />
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default ValentinoPage;
