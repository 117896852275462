/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function JoinPage() {
    const [show, toggleShow] = React.useState(false);

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("contact-page");
        return function cleanup() {
            document.body.classList.remove("contact-page");
        };
    });
    return (
        <>
            <WebNavbar />
            <div className="main">
                <div>
                    <Container>
                        <Row className="section-title text-center mb-4">
                            <Col className="ml-auto mr-auto">
                                <h2 className="title">Join Us</h2>
                                <h5 className="description">
                                    hello@thedivegroup.com
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="text-center pb-5">
                            <Col className="ml-auto mr-auto" md="6">
                                <p>
                                    We are always looking for talents at all levels to join our growing family. Please see below for our current openings.
                                    Send your CV and portfolio to hello@thedivegroup.com to apply.
                                </p>
                            </Col>
                        </Row>
                        <Row className="border-top">
                            <h5 className="ml-5 mt-3 mb-3">Senior Graphic Designer</h5>
                            <h5 className="ml-auto mr-5 mt-3 mb-3 text-right">Shanghai</h5>
                        </Row>
                        <Row className="border-top">
                            <h5 className="ml-5 mt-3 mb-3">Senior UX Designer</h5>
                            <h5 className="ml-auto mr-5 mt-3 mb-3 text-right">Shanghai</h5>
                        </Row>
                        <Row className="border-top" onClick={() => toggleShow(!show)}>
                            <h5 className="ml-5 mt-3 mb-3">Graphic Design Internship</h5>
                            <h5 className="ml-auto mr-5 mt-3 mb-3 text-right">Shanghai</h5>
                        </Row>
                        {show && (
                            <Row>
                                <Col className="ml-auto mr-auto" md="8">
                                    <p className="mt-5 mb-5">
                                        职能描述：<br/>

                                        1.参与品牌、运营、平面设计需求的探索<br/>
                                        2.配合 leader完成客户的视觉设计需求，包括各类海报、banner、插图等<br/>
                                        3.参与优化我们所制作的创意广告素材<br/><br/>

                                        要求：<br/><br/>

                                        1.设计相关专业在校/应届生，热爱艺术设计，充满活力，对流行趋势拥有敏锐的洞察力, attention to detail<br/>
                                        2. 熟悉设计类软件，可以熟练运用PS、AI、ID等绘图软件；（加分项: 会Sketch, AE等）<br/>
                                        3.良好开放的心态，乐于听取不同想法，对设计有要求，对品质有坚持，但对自己的设计不盲目不固执<br/>
                                        4.细心认真，对工作有责任心，<br/>
                                        5.Fluent in English is a plus<br/><br/>

                                        你能得到的：<br/><br/>

                                        1.提供实习补贴、实习证明<br/>
                                        2.丰富好玩的团队活动<br/>
                                        3.咖啡酒水无限量供应<br/>
                                        4.国际化团队，视野的开拓、专业的指导、有价值的个人成长<br/>
                                        5. 转正机会<br/>
                                    </p>
                                </Col>
                            </Row>
                        )}
                        <Row className="border-top border-bottom pb-5">
                            <h5 className="ml-5 mt-3 mb-3">Motion Graphic Designer</h5>
                            <h5 className="ml-auto mr-5 mt-3 mb-3 text-right">Shanghai</h5>
                        </Row>
                    </Container>
                </div>
            </div>
            <SimpleFooter />
        </>
    );
}

export default JoinPage;
