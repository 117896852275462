/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse, Button, CardBody, Card,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem
} from "reactstrap";

function WebNavbar() {
  const [navbarColor, setNavbarColor] = React.useState(false);
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
          document.documentElement.scrollTop > 299 ||
          document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
          document.documentElement.scrollTop < 300 ||
          document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("sticky-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="top"
            to="/index"
            target="_self"
            tag={Link}
          >
            <img
                alt="..."
                src={require("assets/img/bbmVI-16.png")}
                style={{ width: "10%"}}
            />
          </NavbarBrand>
          <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem className="mr-sm-4" id="work">
              <NavLink
                  href="/work-page"
                  target="_self"
              >
                <p style={{color:"black"}}>Work</p>
              </NavLink>
            </NavItem>
            <NavItem className="mr-sm-4">
              <NavLink
                href="/about-page"
                target="_self"
              >
                <p style={{color:"black"}}>About</p>
              </NavLink>
            </NavItem>
            <NavItem className="mr-sm-4">
              <NavLink
                  href="/service-page"
                  target="_self"
              >
                <p style={{color:"black"}}>Services</p>
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <p style={{color:"black"}}>Contact</p>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                    href="/contact-page"
                    target="_self">
                  <p style={{color:"black"}}>Contact Us</p>
                </DropdownItem>
                <DropdownItem
                    href="/join-page"
                    target="_self">
                  <p style={{color:"black"}}>Join Us</p>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>


  );
}

export default WebNavbar;
