/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {Row, Container, NavLink} from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black">
      <Container>
        <row>
          <nav className="footer-nav w-50 float-left mt-lg-5">
            <ul className="mt-lg-5">
              <li>
                <a
                  href="https://www.creative-tim.com?ref=pkr-footer"
                  target="_blank"
                >
                  Creative Tim
                </a>
              </li>
              <li>
                <a
                  href="http://blog.creative-tim.com/?ref=pkr-footer"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://www.creative-tim.com/license?ref=pkr-footer"
                  target="_blank"
                >
                  Licenses
                </a>
              </li>
            </ul>
          </nav>

          <nav className="footer-nav w-50 float-right mt-lg-5">
            <ul className="mt-lg-5">
              <li style={{display:"inline"}}>
                <i className="fa fa-facebook-square"/>
                <p className="d-lg-none">Facebook</p>
              </li>
              <li style={{display:"inline"}}>
                <i className="fa fa-wechat"/>
                <p className="d-lg-none">Wechat</p>
              </li>
              <li style={{display:"inline"}}>
                <i className="fa fa-instagram"/>
                <p className="d-lg-none">Instagram</p>
              </li>
              <br></br>
              <li style={{display:"inline"}}>
                <i className="fa fa-instagram"/>
                <p className="d-lg-none">Instagram</p>
              </li>
              <li style={{display:"inline"}}>
                <i className="fa fa-instagram"/>
                <p className="d-lg-none">Instagram</p>
              </li>
              <li style={{display:"inline"}}>
                <i className="fa fa-instagram"/>
                <p className="d-lg-none">Instagram</p>
              </li>
            </ul>
          </nav>
        </row>
          <div className="credits text-sm-center">
            <span className="copyright" style={{position:"fixed"}}>
            </span>
          </div>

      </Container>
    </footer>
  );
}

export default DemoFooter;
