/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import WebNavbar from "components/Navbars/WebNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

function StickerPage() {
    const [activeTab, setActiveTab] = React.useState("1");
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    const [show, toggleShow] = React.useState(false);

    function visible() {
        if (!show) {
            toggleShow(!show);
        }
    }

    function hide() {
        if (show) {
            toggleShow(!show);
        }
    }

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        return function cleanup() {
            document.body.classList.remove("landing-page");
        };
    });
    return (
        <>
            <WebNavbar/>
            <div className="main">
                <div className="section text-center">
                    <Container>
                        <Row className="section-title mb-4">
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Mid-Autumn Wechat Sticker Design</h2>
                                <h5 className="description">
                                    Graphic Design // Motion Design
                                </h5>
                            </Col>
                        </Row>
                        <hr style={{borderTop: "1px solid #000000"}}/>
                        <Row className="mt-5 mb-3">
                            <img
                                alt="Responsive Image"
                                src={require("assets/img/graphics/mid-autumn/cover.jpg")}
                                style={{objectFit: "cover", height: "400px", width: "100%", borderRadius: "10px"}}
                            />
                        </Row>
                        <p className="text-left small">
                            Deliverables: Brand development (social media stickers)<br/><br/>
                            To develop Bang Bang Motion's digital brand presence and increase brand exposure, a series of festive stickers were created for users on the most popular Chinese social media platform, WeChat.
                        </p>
                        <Row className="mt-5 mb-5">
                            <Col className="ml-auto mr-auto" md="7">
                                <h5> Project Brief </h5>
                                <br/>
                                <p className="text-center">
                                    With the middle-autumn festival approaching, BBM launched a set of Wechat Stickers to
                                    celebrate the festival. The project aimed to develop Bang Bang Motion's digital brand
                                    presence by marketing on the most popular social media platform in China — WeChat.
                                    Understanding that stickers are a crucial part of how users communicate with one
                                    another on the platform, BBM created this series of festive stickers that meets this
                                    demand during this Chinese holiday. This marketing strategy enabled BBM to directly
                                    interact with its audiences while increasing brand exposure.
                                    The project showcases a creative approach to social media that can be
                                    utilized for smart brand marketing.<br/><br/>
                                    The characters of the stickers are mooncakes that have been placed into everyday
                                    scenarios: working overtime, pushing ourselves to
                                    work out, and even some special stickers reflective of the pandemic, such as taking classes online
                                    and sharing a virtual glass of wine with friends.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/01.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/02.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/03.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/04.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/05.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/06.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/07.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/08.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                        </Row>
                        <p className="small mb-5">
                            Daily to-go phrases that encourage the users to interact with friends using our stickers.
                        </p>

                        <p className="mt-5 text-center w-50 ml-auto mr-auto mb-5">
                            WeChat users love stickers with silly and awkward characteristics, understanding this, the
                            design incorporated many local catchphrases and pop-culture memes. For instance, it includes
                            an interesting debate on the "wuren", a traditional mixed nut mooncake flavor.<br/><br/>
                            The stickers follow BBM's design language with our classic thin outlines and simple yet playful visual style.
                        </p>

                        <Row className="mt-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/09.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/10.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/11.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/12.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                        </Row>
                        <p className="small mt-5 mb-5">
                            Stickers that mention the debate over the "wuren" flavor.
                        </p>
                        <Row className="mb-5">
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/13.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/14.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/15.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                            <Col className="ml-auto mr-auto" md="3">
                                <img
                                    alt="Responsive Image"
                                    src={require("assets/img/graphics/mid-autumn/16.gif")}
                                    style={{width: "80%"}}
                                />
                            </Col>
                        </Row>
                        <p className="small mt-3 mb-5">
                            Stickers designed for life during the pandemic.
                        </p>
                        <p className="mt-5 text-center w-50 ml-auto mr-auto mb-5">
                            We'll release more fun sticker designs in the future! Stay tuned!
                        </p>
                    </Container>
                </div>
            </div>

            <SimpleFooter/>
        </>
    );
}

export default StickerPage;
